/* Start Protion creator */
.BusinessPageEditorPortionCon1 {
  display: flex;
  padding: 12px;
  padding-bottom: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  width: 100%;
  box-sizing: border-box;
  /* height: 94px; */
  /* flex: 1 0 0; */
  border-radius: 15px;
  background: none;
}
.BusinessPageEditorPortionCon2 {
  display: flex;
  padding: 2px 0px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-bottom: 1px solid var(--app-white, #fff);
}
.BusinessPageEditorPortionCon3 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}
.BusinessPageEditorPortionHeaderText {
  flex: 1 0 0;
  color: var(--app-charcoal-dark, #2b2b2b);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

/* End Protion creator */
/* Start header */
.BusinessPageEditorSelectImgCon1 {
  display: flex;
  padding: 4px 6px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 5px;
  background: var(--app-white, #fff);
}
.BusinessPageEditorSelectImgLabel {
  color: var(--app-gray-medium, #6d6d6d);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.InputMiniCon1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
}
.InputMiniCon2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  align-self: stretch;
}
.InputMiniLabel {
  color: var(--app-gray-medium, #6d6d6d);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.24px;
}
.InputMiniInput {
  display: flex;
  height: 23px;
  padding: 2px 6px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 0px;
  border-bottom: 1px solid var(--app-charcoal-dark, #2b2b2b);
  background: var(--app-white, #fff);

  flex: 1 0 0;
  color: var(--app-gray-medium, #6d6d6d);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
/* End header */
/* Start listings */
.BusinessPageEditorListingsApearCon1A {
  width: 100%;
  padding-left: 12px;
  padding-right: 12px;
  justify-content: center;
  align-items: flex-end;
  gap: 24px;
  display: inline-flex;
}
.BusinessPageEditorListingsApearCon1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 6px;
  border: 0.5px solid var(--app-charcoal-dark, #2b2b2b);
  background: var(--app-gray-soft, #e0e0e0);
}
.BusinessPageEditorListingsApearCon1Active {
  border-radius: 6px;
  border: 0.5px solid var(--app-charcoal-dark, #2b2b2b);
  background: var(--app-white, #fff);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.BusinessPageEditorListingsApearCon2 {
  padding: 6px;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  display: inline-flex;
}

.BusinessPageEditorListingsApearCon3 {
  display: flex;
  width: 48px;
  height: 63px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 6px;
  border: 0.5px solid var(--app-white, #fff);
  background: var(
    --MAI-linear,
    linear-gradient(0deg, #03c11f 0%, #fffb00 100%)
  );
  overflow: hidden;
}

/*  */

.BusinessPageEditorListingsApearConB1 {
  display: flex;
  height: 23px;
  /* padding: 10px; */
  align-items: flex-start;
  gap: 10px;
  flex-shrink: 0;
  align-self: stretch;
  background: rgba(0, 0, 0, 0.3);
}
.BusinessPageEditorListingsApearConB2 {
  display: flex;
  padding: 3px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
}
.BusinessPageEditorListingsApearConB3 {
  height: 6px;
  align-self: stretch;
  border-radius: 1px;
  background: var(--app-white, #fff);
}
.BusinessPageEditorListingsApearConB4 {
  width: 22px;
  height: 4px;
  border-radius: 1px;
  background: var(--app-white, #fff);
}
.BusinessPageEditorListingsApearConB5 {
  height: 15px;
  align-self: stretch;
  border-radius: 1px;
  background: var(--app-gray-soft, #e0e0e0);
}
.BusinessPageEditorListingsApearConB6 {
  display: flex;
  padding: 0px 1px;
  align-items: flex-start;
  gap: 3px;
  align-self: stretch;
}
.BusinessPageEditorListingsApearConB7 {
  height: 3px;
  flex: 1 0 0;
  border-radius: 1px;
  background: var(--app-white, #fff);
}
.BusinessPageEditorListingsApearConB8 {
  width: 9px;
  height: 3px;
  border-radius: 1px;
  background: var(--app-white, #fff);
}
.BusinessPageEditorListingsApearConB9 {
  width: 35px;
  align-self: stretch;
  background: rgba(0, 0, 0, 0.3);
}
.BusinessPageEditorListingsApearConB10 {
  display: flex;
  width: 83px;
  padding: 3px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}
.BusinessPageEditorListingsApearConB11 {
  width: 58px;
  height: 6px;
  border-radius: 1px;
  background: var(--app-white, #fff);
}
.BusinessPageEditorListingsApearConB12 {
  width: 22px;
  height: 6px;
  border-radius: 1px;
  background: var(--app-white, #fff);
}
.BusinessPageEditorListingsApearConB13 {
  height: 12px;
  align-self: stretch;
  border-radius: 1px;
  background: var(--app-gray-soft, #e0e0e0);
}
.BusinessPageEditorListingsApearConB14 {
  display: flex;
  padding: 0px 3px;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}
.BusinessPageEditorListingsApearConB15 {
  height: 3px;
  flex: 1 0 0;
  border-radius: 1px;
  background: var(--app-white, #fff);
}
.BusinessPageEditorListingsApearConB16 {
  width: 24px;
  height: 3px;
  border-radius: 1px;
  background: var(--app-white, #fff);
}

/*  */

.BusinessPageEditorListingsApearText {
  text-align: center;
  color: black;
  font-size: 12px;
  font-family: Inter;
  font-weight: 400;
  word-wrap: break-word;
}

.BusinessPageEditorListingsApearCon4 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 6px;
  border: 0.5px solid var(--app-charcoal-dark, #2b2b2b);
  background: var(--app-gray-soft, #e0e0e0);
}

.BusinessPageEditorListingsApearCon5 {
  padding: 6px;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  display: inline-flex;
}

.BusinessPageEditorListingsApearCon6 {
  display: flex;
  width: 118px;
  height: 42px;
  align-items: flex-start;
  border-radius: 6px;
  border: 0.5px solid var(--app-white, #fff);
  background: var(
    --MAI-linear,
    linear-gradient(0deg, #03c11f 0%, #fffb00 100%)
  );
  overflow: hidden;
}

.BusinessPageEditorListingsListsCon1 {
  align-self: stretch;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  display: flex;
}

.BusinessPageEditorListingsListsCon2 {
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 12px 0px 0px 12px;
  background: var(--app-white, #fff);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  overflow: hidden;
}

.BusinessPageEditorListingsListsCon2.dragging {
  opacity: 0.5;
  background-color: #f0f0f0;
  border: 2px dashed #0085ff;
}

.BusinessPageEditorListingsListsCon2B {
  display: flex;
  padding: 12px 6px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;

  background: var(--app-gray-soft, #e0e0e0);
}

.BusinessPageEditorListingsListsCon3 {
  align-self: stretch;
  padding-left: 4px;
  padding-right: 4px;
  padding-top: 12px;
  padding-bottom: 12px;
  background: white;
  border-radius: 5px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: flex;
}

.BusinessPageEditorListingsListsCon4 {
  display: flex;
  padding: 6px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  flex: 1 0 0;
}

.BusinessPageEditorListingsListsCon5 {
  align-self: stretch;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 2px;
  display: flex;
}

.BusinessPageEditorListingsListsLabel {
  text-align: center;
  color: #2b2b2b;
  font-size: 8px;
  font-family: Inter;
  font-weight: 400;
  word-wrap: break-word;
}

.BusinessPageEditorListingsListsInputName {
  align-self: stretch;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  background: white;
  border-bottom: 0.5px #2b2b2b solid;
  justify-content: center;
  align-items: center;
  gap: 12px;
  display: inline-flex;

  flex: 1 0 0;
  color: var(--app-gray-medium, #6d6d6d);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.BusinessPageEditorListingsListsChoose {
  align-self: stretch;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 2px;
  padding-bottom: 2px;
  background: white;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border: 0.5px #2b2b2b solid;
  justify-content: center;
  align-items: center;
  gap: 12px;
  display: inline-flex;

  flex: 1 0 0;
  color: var(--app-gray-medium, #6d6d6d);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.BusinessPageEditorListingsListsCon7 {
  display: flex;
  padding: 0px 12px;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
  align-self: stretch;
}

.BusinessPageEditorListingsListsButtonNewList {
  display: flex;
  padding: 4px 6px;
  justify-content: center;
  align-items: center;
  gap: 6px;

  border-radius: 5px;
  border: 0.5px solid var(--app-white, #fff);
  background: var(
    --MAI-linear,
    linear-gradient(0deg, #03c11f 0%, #fffb00 100%)
  );
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  color: var(--app-green, #3a7d44);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.BusinessPageEditorInfoCon1 {
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 6px;
  display: inline-flex;
}

.BusinessPageEditorInfoCon2 {
  align-self: stretch;
  padding-left: 6px;
  padding-right: 6px;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  display: inline-flex;
}
.BusinessEditorLocationMyLocationCon1 {
  display: flex;
  padding: 3px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;
  border-radius: 6px;
  background: var(--app-white, #fff);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
}
.BusinessInfoPortionLinkText {
  flex: 1 1 0;
  color: #00349b;
  font-size: 20px;
  font-family: Inter;
  font-weight: 400;
  letter-spacing: 0.2px;
  word-wrap: break-word;
}

.BusinessEditorInfoTextAreaCon1 {
  align-self: stretch;
  min-height: 144px;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 2px;
  padding-bottom: 2px;
  background: white;
  border: none;
  border-bottom: 1px #6d6d6d solid;
  justify-content: flex-start;
  align-items: flex-start;
  display: inline-flex;

  flex: 1 0 0;

  color: var(--app-charcoal-dark, #2b2b2b);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.32px;
}
.BusinessEditorInfoTextAreaTextArea {
  width: 100%;
  height: 100%;
  flex: 1 0 0;

  color: var(--app-charcoal-dark, #2b2b2b);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.32px;
}
/* End listings */
/* Start colors */
.BusinessPageEditorColorsCon1 {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 6px;
  display: inline-flex;
}

.BusinessPageEditorColorsTitle {
  text-align: center;
  color: black;
  font-size: 12px;
  font-family: Inter;
  font-weight: 400;
  word-wrap: break-word;
}

.BusinessPageEditorColorsCon2 {
  display: flex;
  padding: 0px 6px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}

.BusinessPageEditorColorsCon3B {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  align-self: stretch;
}
.BusinessPageEditorColorsCon3 {
  display: flex;
  padding: 6px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 6px;
  border: 1px solid var(--app-white, #fff);
  background: var(
    --MAI-linear,
    linear-gradient(0deg, #03c11f 0%, #fffb00 100%)
  );
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
}

.BusinessPageEditorColorsColorInEditor {
  height: 18px;
  /* flex: 1 0 0; */
  width: 100%;
  border-radius: 3px;
  background: var(--app-white, #fff);
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.25);
}
.BusinessPageEditorColorsColor {
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 0.5px solid var(--app-charcoal-dark, #2b2b2b);
  background: var(--app-white, #fff);
}

.BusinessPageEditorColorsLabel {
  color: var(--app-green, #3a7d44);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

/*  */

.BusinessPageEditorColorsCon4 {
  display: flex;
  padding: 2px 0px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-bottom: 1px solid var(--Success-color-green, #33b546);
}

.BusinessPageEditorColorsHeaderText {
  flex: 1 1 0;
  color: var(--Success-color-green, #33b546);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.BusinessPageEditorColorsCon5 {
  /* width: 366px;
  height: 24px; */
  justify-content: flex-start;
  align-items: flex-start;
  gap: 6px;
  display: inline-flex;
}

.BusinessPageEditorColorsCon6 {
  padding: 4px;
  background: white;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 2px;
  display: flex;
}

.BusinessPageEditorColorPalleteCon1 {
  /* display: flex; */
  max-width: 310px;
  /* padding: 24px 12px 0px 12px; */
  /* flex-direction: column;
  align-items: center;
  gap: 12px; */

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;

  /* border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25); */
}
.BusinessPageEditorColorPalleteCon2 {
  display: flex;
  align-items: center;
  align-content: center;
  max-width: 310px;
  gap: 12px;
  align-self: stretch;
  flex-wrap: wrap;
  padding: 24px 12px 12px 12px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.BusinessPageEditorColorPalleteClose {
  display: none;
  /* width: 32px; */
  height: 32px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  background: #fff;
}

.BusinessPageEditorColorPalleteCon {
  position: absolute;
  /* min-height: 100px; */
  height: 100%;
  align-self: stretch;
  width: 100%;
  display: flex;
  /* padding: 12px 0px; */
  justify-content: center;

  background: #00000016;
  gap: 10px;
  flex: 1 0 0;
  transform: translateY(-100%);
  /* border-bottom-left-radius: 24px; */
  /* border-bottom-right-radius: 24px; */
  z-index: 100;
  transition: transform 0.3s ease-in-out;
}
/* End colors */
.BusinessPageEditorMapContainer {
  height: 100%; /* Adjust the height as needed */
  width: 100%; /* You can set a fixed width or percentage */
  border-radius: 20px;
  border: solid 1px white;
}
#BusinessPageEditorLocationInput {
  width: 100%;
  padding: 6px 12px;
  border-radius: 5px;
  border: solid 1px #00349b;
  box-sizing: border-box;
}

#BusinessPageEditorMapContainer1 {
  height: 200px;
  width: 100%;
  position: relative;
}

#BusinessPageEditorMapCenterMarker {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 6px;
  height: 6px;
  /* background-color: #fff; */

  display: flex;
  /* width: 48px;
height: 48px; */

  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: #4784ff5b;
  /* border: solid 3px #1765ff; */
  /* border: solid 3px #4784ff; */
  border-radius: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none; /* Make sure this is non-clickable */
  z-index: 1000;
}

.BusinessEditorLocationMyLocationText {
  text-align: center;
  color: #6d6d6d;
  font-size: 8px;
  font-family: Inter;
  font-weight: 500;
  word-wrap: break-word;
}

.BusinessEditorColorGroupSelectionCon1 {
  /* width: 139px; */
  /* height: 31px; */
  padding: 6px;
  background: #ffffff;
  border-radius: 5px;
  overflow: hidden;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: inline-flex;
}
.BusinessEditorColorGroupSelectionCon1Active {
  border: 1px #00349b solid;
}
.BusinessEditorColorGroupSelectionCon1A {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 12px;
  align-self: stretch;
  flex-wrap: wrap;
}

.BusinessEditorColorGroupSelectionCon2 {
  /* padding: 0.5px; */
  border: 0.5px black solid;
  justify-content: flex-start;
  align-items: center;
  display: inline-flex;
}

.BusinessEditorColorGroupSelectionColor {
  width: 18px;
  height: 18px;
  position: relative;
  background: white;
}

/*  */
.BusinessPageEditorListingsApearButtonCon1 {
  display: flex;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  border: 1px solid var(--app-white, #fff);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  color: var(--app-white, #fff);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.BusinessPageEditorListingsApearButtonCon2 {
  display: flex;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  background: var(--app-white, #fff);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  color: var(--Success-color-green, #33b546);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
