.BusinessPageEditorPortionCon1 {
  box-sizing: border-box;
  background: none;
  border-radius: 15px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  width: 100%;
  padding: 12px 12px 24px;
  display: flex;
}

.BusinessPageEditorPortionCon2 {
  border-bottom: 1px solid var(--app-white, #fff);
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  padding: 2px 0;
  display: flex;
}

.BusinessPageEditorPortionCon3 {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 24px;
  display: flex;
}

.BusinessPageEditorPortionHeaderText {
  color: var(--app-charcoal-dark, #2b2b2b);
  text-align: center;
  flex: 1 0 0;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.BusinessPageEditorSelectImgCon1 {
  background: var(--app-white, #fff);
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  padding: 4px 6px;
  display: flex;
}

.BusinessPageEditorSelectImgLabel {
  color: var(--app-gray-medium, #6d6d6d);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.InputMiniCon1 {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 4px;
  display: flex;
}

.InputMiniCon2 {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 6px;
  display: flex;
}

.InputMiniLabel {
  color: var(--app-gray-medium, #6d6d6d);
  letter-spacing: .24px;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.InputMiniInput {
  border-bottom: 1px solid var(--app-charcoal-dark, #2b2b2b);
  background: var(--app-white, #fff);
  height: 23px;
  color: var(--app-gray-medium, #6d6d6d);
  border-radius: 0;
  flex: 1 0 0;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  padding: 2px 6px;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
}

.BusinessPageEditorListingsApearCon1A {
  justify-content: center;
  align-items: flex-end;
  gap: 24px;
  width: 100%;
  padding-left: 12px;
  padding-right: 12px;
  display: inline-flex;
}

.BusinessPageEditorListingsApearCon1 {
  border: .5px solid var(--app-charcoal-dark, #2b2b2b);
  background: var(--app-gray-soft, #e0e0e0);
  border-radius: 6px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;
  display: flex;
}

.BusinessPageEditorListingsApearCon1Active {
  border: .5px solid var(--app-charcoal-dark, #2b2b2b);
  background: var(--app-white, #fff);
  border-radius: 6px;
  box-shadow: 0 4px 4px #00000040;
}

.BusinessPageEditorListingsApearCon2 {
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  padding: 6px;
  display: inline-flex;
}

.BusinessPageEditorListingsApearCon3 {
  border: .5px solid var(--app-white, #fff);
  background: var(--MAI-linear, linear-gradient(0deg, #03c11f 0%, #fffb00 100%));
  border-radius: 6px;
  flex-direction: column;
  align-items: flex-start;
  width: 48px;
  height: 63px;
  display: flex;
  overflow: hidden;
}

.BusinessPageEditorListingsApearConB1 {
  background: #0000004d;
  flex-shrink: 0;
  align-self: stretch;
  align-items: flex-start;
  gap: 10px;
  height: 23px;
  display: flex;
}

.BusinessPageEditorListingsApearConB2 {
  flex-direction: column;
  flex: 1 0 0;
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  padding: 3px;
  display: flex;
}

.BusinessPageEditorListingsApearConB3 {
  background: var(--app-white, #fff);
  border-radius: 1px;
  align-self: stretch;
  height: 6px;
}

.BusinessPageEditorListingsApearConB4 {
  background: var(--app-white, #fff);
  border-radius: 1px;
  width: 22px;
  height: 4px;
}

.BusinessPageEditorListingsApearConB5 {
  background: var(--app-gray-soft, #e0e0e0);
  border-radius: 1px;
  align-self: stretch;
  height: 15px;
}

.BusinessPageEditorListingsApearConB6 {
  align-self: stretch;
  align-items: flex-start;
  gap: 3px;
  padding: 0 1px;
  display: flex;
}

.BusinessPageEditorListingsApearConB7 {
  background: var(--app-white, #fff);
  border-radius: 1px;
  flex: 1 0 0;
  height: 3px;
}

.BusinessPageEditorListingsApearConB8 {
  background: var(--app-white, #fff);
  border-radius: 1px;
  width: 9px;
  height: 3px;
}

.BusinessPageEditorListingsApearConB9 {
  background: #0000004d;
  align-self: stretch;
  width: 35px;
}

.BusinessPageEditorListingsApearConB10 {
  flex-direction: column;
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  width: 83px;
  padding: 3px;
  display: flex;
}

.BusinessPageEditorListingsApearConB11 {
  background: var(--app-white, #fff);
  border-radius: 1px;
  width: 58px;
  height: 6px;
}

.BusinessPageEditorListingsApearConB12 {
  background: var(--app-white, #fff);
  border-radius: 1px;
  width: 22px;
  height: 6px;
}

.BusinessPageEditorListingsApearConB13 {
  background: var(--app-gray-soft, #e0e0e0);
  border-radius: 1px;
  align-self: stretch;
  height: 12px;
}

.BusinessPageEditorListingsApearConB14 {
  align-self: stretch;
  align-items: flex-start;
  gap: 12px;
  padding: 0 3px;
  display: flex;
}

.BusinessPageEditorListingsApearConB15 {
  background: var(--app-white, #fff);
  border-radius: 1px;
  flex: 1 0 0;
  height: 3px;
}

.BusinessPageEditorListingsApearConB16 {
  background: var(--app-white, #fff);
  border-radius: 1px;
  width: 24px;
  height: 3px;
}

.BusinessPageEditorListingsApearText {
  text-align: center;
  color: #000;
  word-wrap: break-word;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
}

.BusinessPageEditorListingsApearCon4 {
  border: .5px solid var(--app-charcoal-dark, #2b2b2b);
  background: var(--app-gray-soft, #e0e0e0);
  border-radius: 6px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;
  display: flex;
}

.BusinessPageEditorListingsApearCon5 {
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  padding: 6px;
  display: inline-flex;
}

.BusinessPageEditorListingsApearCon6 {
  border: .5px solid var(--app-white, #fff);
  background: var(--MAI-linear, linear-gradient(0deg, #03c11f 0%, #fffb00 100%));
  border-radius: 6px;
  align-items: flex-start;
  width: 118px;
  height: 42px;
  display: flex;
  overflow: hidden;
}

.BusinessPageEditorListingsListsCon1 {
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 24px;
  display: flex;
}

.BusinessPageEditorListingsListsCon2 {
  background: var(--app-white, #fff);
  border-radius: 12px 0 0 12px;
  align-self: stretch;
  align-items: flex-start;
  display: flex;
  overflow: hidden;
  box-shadow: 0 4px 4px #00000040;
}

.BusinessPageEditorListingsListsCon2.dragging {
  opacity: .5;
  background-color: #f0f0f0;
  border: 2px dashed #0085ff;
}

.BusinessPageEditorListingsListsCon2B {
  background: var(--app-gray-soft, #e0e0e0);
  flex-direction: column;
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  padding: 12px 6px;
  display: flex;
}

.BusinessPageEditorListingsListsCon3 {
  background: #fff;
  border-radius: 5px;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  padding: 12px 4px;
  display: flex;
}

.BusinessPageEditorListingsListsCon4 {
  flex-direction: column;
  flex: 1 0 0;
  align-items: flex-start;
  gap: 12px;
  padding: 6px;
  display: flex;
}

.BusinessPageEditorListingsListsCon5 {
  flex-direction: column;
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
  gap: 2px;
  display: flex;
}

.BusinessPageEditorListingsListsLabel {
  text-align: center;
  color: #2b2b2b;
  word-wrap: break-word;
  font-family: Inter;
  font-size: 8px;
  font-weight: 400;
}

.BusinessPageEditorListingsListsInputName {
  color: var(--app-gray-medium, #6d6d6d);
  background: #fff;
  border-bottom: .5px solid #2b2b2b;
  flex: 1 0 0;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 12px;
  padding: 4px 6px;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: inline-flex;
}

.BusinessPageEditorListingsListsChoose {
  color: var(--app-gray-medium, #6d6d6d);
  background: #fff;
  border: .5px solid #2b2b2b;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  flex: 1 0 0;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 12px;
  padding: 2px 6px;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: inline-flex;
}

.BusinessPageEditorListingsListsCon7 {
  justify-content: flex-end;
  align-self: stretch;
  align-items: center;
  gap: 12px;
  padding: 0 12px;
  display: flex;
}

.BusinessPageEditorListingsListsButtonNewList {
  border: .5px solid var(--app-white, #fff);
  background: var(--MAI-linear, linear-gradient(0deg, #03c11f 0%, #fffb00 100%));
  color: var(--app-green, #3a7d44);
  text-align: center;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  padding: 4px 6px;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  box-shadow: 0 4px 4px #00000040;
}

.BusinessPageEditorInfoCon1 {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 6px;
  width: 100%;
  display: inline-flex;
}

.BusinessPageEditorInfoCon2 {
  justify-content: flex-start;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  padding-left: 6px;
  padding-right: 6px;
  display: inline-flex;
}

.BusinessEditorLocationMyLocationCon1 {
  background: var(--app-white, #fff);
  border-radius: 6px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;
  padding: 3px;
  display: flex;
  box-shadow: 0 2px 4px #00000040;
}

.BusinessInfoPortionLinkText {
  color: #00349b;
  letter-spacing: .2px;
  word-wrap: break-word;
  flex: 1 1 0;
  font-family: Inter;
  font-size: 20px;
  font-weight: 400;
}

.BusinessEditorInfoTextAreaCon1 {
  min-height: 144px;
  color: var(--app-charcoal-dark, #2b2b2b);
  letter-spacing: .32px;
  background: #fff;
  border: none;
  border-bottom: 1px solid #6d6d6d;
  flex: 1 0 0;
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
  padding: 2px 12px;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: inline-flex;
}

.BusinessEditorInfoTextAreaTextArea {
  width: 100%;
  height: 100%;
  color: var(--app-charcoal-dark, #2b2b2b);
  letter-spacing: .32px;
  flex: 1 0 0;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.BusinessPageEditorColorsCon1 {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 6px;
  display: inline-flex;
}

.BusinessPageEditorColorsTitle {
  text-align: center;
  color: #000;
  word-wrap: break-word;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
}

.BusinessPageEditorColorsCon2 {
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  padding: 0 6px;
  display: flex;
}

.BusinessPageEditorColorsCon3B {
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 6px;
  display: flex;
}

.BusinessPageEditorColorsCon3 {
  border: 1px solid var(--app-white, #fff);
  background: var(--MAI-linear, linear-gradient(0deg, #03c11f 0%, #fffb00 100%));
  border-radius: 6px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;
  padding: 6px;
  display: flex;
  box-shadow: 0 2px 4px #00000040;
}

.BusinessPageEditorColorsColorInEditor {
  background: var(--app-white, #fff);
  border-radius: 3px;
  width: 100%;
  height: 18px;
  box-shadow: 0 0 2px 1px #00000040;
}

.BusinessPageEditorColorsColor {
  border: .5px solid var(--app-charcoal-dark, #2b2b2b);
  background: var(--app-white, #fff);
  border-radius: 10px;
  flex-shrink: 0;
  width: 32px;
  height: 32px;
}

.BusinessPageEditorColorsLabel {
  color: var(--app-green, #3a7d44);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.BusinessPageEditorColorsCon4 {
  border-bottom: 1px solid var(--Success-color-green, #33b546);
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  padding: 2px 0;
  display: flex;
}

.BusinessPageEditorColorsHeaderText {
  color: var(--Success-color-green, #33b546);
  text-align: center;
  flex: 1 1 0;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.BusinessPageEditorColorsCon5 {
  justify-content: flex-start;
  align-items: flex-start;
  gap: 6px;
  display: inline-flex;
}

.BusinessPageEditorColorsCon6 {
  background: #fff;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 2px;
  padding: 4px;
  display: flex;
}

.BusinessPageEditorColorPalleteCon1 {
  flex-direction: column;
  align-items: center;
  gap: 12px;
  max-width: 310px;
  display: flex;
}

.BusinessPageEditorColorPalleteCon2 {
  background: #fff;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
  flex-wrap: wrap;
  align-content: center;
  align-self: stretch;
  align-items: center;
  gap: 12px;
  max-width: 310px;
  padding: 24px 12px 12px;
  display: flex;
  box-shadow: 0 4px 4px #00000040;
}

.BusinessPageEditorColorPalleteClose {
  background: #fff;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  height: 32px;
  display: none;
}

.BusinessPageEditorColorPalleteCon {
  z-index: 100;
  background: #00000016;
  flex: 1 0 0;
  justify-content: center;
  align-self: stretch;
  gap: 10px;
  width: 100%;
  height: 100%;
  transition: transform .3s ease-in-out;
  display: flex;
  position: absolute;
  transform: translateY(-100%);
}

.BusinessPageEditorMapContainer {
  border: 1px solid #fff;
  border-radius: 20px;
  width: 100%;
  height: 100%;
}

#BusinessPageEditorLocationInput {
  box-sizing: border-box;
  border: 1px solid #00349b;
  border-radius: 5px;
  width: 100%;
  padding: 6px 12px;
}

#BusinessPageEditorMapContainer1 {
  width: 100%;
  height: 200px;
  position: relative;
}

#BusinessPageEditorMapCenterMarker {
  pointer-events: none;
  z-index: 1000;
  background-color: #4784ff5b;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 6px;
  height: 6px;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.BusinessEditorLocationMyLocationText {
  text-align: center;
  color: #6d6d6d;
  word-wrap: break-word;
  font-family: Inter;
  font-size: 8px;
  font-weight: 500;
}

.BusinessEditorColorGroupSelectionCon1 {
  background: #fff;
  border-radius: 5px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 6px;
  display: inline-flex;
  overflow: hidden;
}

.BusinessEditorColorGroupSelectionCon1Active {
  border: 1px solid #00349b;
}

.BusinessEditorColorGroupSelectionCon1A {
  flex-wrap: wrap;
  align-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
  gap: 12px;
  display: flex;
}

.BusinessEditorColorGroupSelectionCon2 {
  border: .5px solid #000;
  justify-content: flex-start;
  align-items: center;
  display: inline-flex;
}

.BusinessEditorColorGroupSelectionColor {
  background: #fff;
  width: 18px;
  height: 18px;
  position: relative;
}

.BusinessPageEditorListingsApearButtonCon1 {
  border: 1px solid var(--app-white, #fff);
  color: var(--app-white, #fff);
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 4px 12px;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  box-shadow: 0 4px 4px #00000040;
}

.BusinessPageEditorListingsApearButtonCon2 {
  background: var(--app-white, #fff);
  color: var(--Success-color-green, #33b546);
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 4px 12px;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  box-shadow: 0 4px 4px #00000040;
}
/*# sourceMappingURL=index.141ec7b3.css.map */
